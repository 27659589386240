
.root {
  width: 35px;
  height: 20px;
  display: flex;
  align-items: center;
}

.slot {
  height: 12px;
  width: 100%;
  border-radius: 6px;
  background: var(--background4);
  margin-left: 3px;
  margin-right: 3px;

  &.readOnly {
    background: var(--background3);

    &.off div.nob{
      background: var(--background3);
    }
    &.on div.nob{
        background: var(--background6);
    }
  }

  &.off div{
    left: -2px;
  }

  &.on{
    div{
      left: 10px;
      background: var(--foreground1);
    }
  }

  &.undefined{
    div{
      left: 4.5px;
      background: var(--background1);
      border: var(--background8)
    }
  }

}

.nob {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: var(--background3);
  position: relative;
  top: -4px;
  box-shadow: 0 2px 5px var(--background5);
  transition: left 0.5s, background-color 0.5s, transform 0.5s;
}


