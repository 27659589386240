/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";
@import "~@origam/styles/dist/styles/EditorsCommon.module";

.root {
  width: 100%;
  height: 100%;
  background: var(--background1);
  border: 1px solid var(--background5);
  box-sizing: border-box;
  color: var(--background8);
  padding: 0 3px;
  border-radius: 2px;
  transition: all 300ms linear;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  //justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;

  &:global(.isReadonly) {
    background-color: var(--background3);
    cursor: not-allowed;
    border: 1px solid var(--background3);
  }
}

.focusedLabel {
  background-color: var(--foreground1);
  color:var(--background1);
}

:global(.isReadonly) {
  .focusedLabel {
    background-color: var(--background4);
    color: var(--background8);
  }
}

.unFocusedLabel {
  background-color: transparent;
}

.item {
  min-width: 25%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  @include hoverItemAnimation;

  &:hover {
    color: var(--foreground1);
  }
  :global(.checkbox) {
    padding: 5px;
    flex-shrink: 0;
    overflow: hidden;
    cursor: pointer;
  }

  :global(.content) {
    flex-grow: 1;
    overflow: hidden;
   white-space: nowrap;
  }
}

:global(.isReadonly) {
  .item {
    cursor: not-allowed; 
    &:hover {
      background-color: var(--background3);
      color:var(--background8);
    }
  }
  input {
    background-color: var(--background3);
    cursor: not-allowed !important; 
    opacity: 0.5;
  }
  ::selection {
    background-color: transparent;
  }
}

.editorContainer {
  @include editorContainer;
}
