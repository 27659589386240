/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/definitions";

.root{
  display: flex;
  flex-direction: row;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.header {
  @extend .noSelect;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  font-weight: 700;
  cursor: default;
  background: var(--background2);
  border: none;
  min-height: 25px;
  padding: 0 0 0 0.4em;
  transition: background-color 200ms linear;
  &:hover {
    background: var(--background3);
    .Header_order {
      background: var(--background3);
    }
  }
  &.changing-order {
    color: var(--background8);
  }
  .Header_order {
    background: var(--background2);
    color: var(--foreground1);
    font-size: $font-size;
    height: 25px;
    line-height: 25px;
    padding: 0 0.5em;
    right: -3px;
    @extend %transition;
  }
}

.label {
  box-sizing: border-box;
  align-items: center;
  flex-grow: 1;
  display: block;
  line-height: 25px;
  margin: 0;
  justify-content: left;
  overflow: hidden;
  text-overflow: ellipsis;
 white-space: nowrap;
  word-wrap: normal;
  width: 100%;
}

.order {
  position: absolute;
  right: 4px;
  font-size: 11px;
  background-color: var(--background3);
  padding: 2px;
  height: 20px;
}

.middleSeparator{
  width: 5px;
  border-width: 0 2px;
}

.rightSeparator{
  width: 3px;
  border-width: 0 0 0 2px;
}

.columnWidthHandle {
  flex-shrink: 0;
  background: var(--background3);
  border-color: var(--background2);
  border-style: solid;
  cursor: ew-resize;
  margin: 0;
  padding: 0;
  height: 100%;
  @extend %transition;
  &:hover, &:focus {
    background: var(--foreground1);
  }
  &:global(.isColumnDropTarget) {
    border-color: var(--brown);
  }
}

.inHeaderRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;
  min-height: 25px;
}

.additionalContentsRow {
  width: 100%;
}
