/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/definitions";

.columnTable {
  width: 640px;
  height: 400px;
  margin-bottom: 20px;
}

.lockedColumns {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-bottom: 5px;
}

.lockedColumnsInput {
  width: 38px;
  margin-left: 10px;
}

.checkBoxCell{
  justify-content: center;
}

.columnTableCell {
  height: 25px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid var(--background3);
}

.columnTableCell:global(.header) {
  font-weight: bold;
}

.columnTableCell:global(.odd) {
  background-color: var(--background2);
}

.columnTableCell:global(.even) {
  background-color: var(--background1);
}

.columnWidthHandle {
  position: absolute;
  width: 3px;
  height: 100%;
  right: 0;
  background: var(--background3);
  border-color: var(--background1);
  border-style: solid;
  border-width: 0 1px;
}

.checkBox {
  display: flex;
  align-items: center;
}
.checkBox div {
  padding-top: 1px;
  min-width: 8px;
}

.columnTableCell .dropdown{
  height: 19px;
  width: 72.5px;
}
