/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";

.anchor {
  height: 30px;
  padding: 0 1.6667em;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;

  cursor: pointer;
  text-decoration: none;
  font-style: normal;

  color: $textColorNoHover;

  background-color: var(--background2);

  @include hoverItemAnimation;

  &:global(.isOpenedScreen) {
    background-color: var(--background1);
  }

  &:global(.isHighLighted) {
    color: var(--background1);
    background-color: var(--foreground1);
    font-weight: bold;
    svg:global(.icon) {
      stroke: var(--background1);
      stroke-width: 1;
    }
  }

  &:global(.isActiveScreen) {
    background-color: var(--background1);
    color: var(--foreground1);
    font-weight: 700;

    svg:global(.icon) {
      stroke: var(--foreground1);
      stroke-width: 2;
    }
  }

  &:global(.isHidden) {
    display: none;
  }

  &:not(:global(.isActive)):hover {
    color: $textColorHover;
    svg:global(.icon) {
      stroke-width: 1.5;
    }
  }

  svg:global(.icon) {
    @include hoverItemAnimation;
  }
}

.icon {
  width: 1.6667em;
  height: 1.6667em;
  margin: 0 1em 0 0;
  flex-shrink: 0;
}

.label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.linkContainer {
  width: 100%;
  overflow: hidden;
}


