.resultGroupRow {
  padding-left: 15px;
  font-weight: bold;
  height: 28px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--background1);
  background-color: var(--background2);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.groupName{
  margin-left: 10px;
}

.arrow{
  font-size: 20px;
  color:var(--background8);
}
