/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.dialogContent {
  padding: 10px;
  min-width: 300px;
  max-height: 40vh;
  overflow-y: auto;

  :global(.list) {
    display: flex;
    flex-direction: column;
    :global(.listItem) {
      display: flex;
      flex-direction: row;
      :global(.itemIcon) {
        width: 1.5em;
        height: 1.5em;
        flex: 0 0 1.5em;
        &:global(.error) {
          svg {
            fill: var(--error1);
            stroke: transparent;
          }
        }
        &:global(.warning) {
          svg {
            fill: var(--warning1);
            stroke: transparent;
          }
        }
      }
      :global(.itemMessage) {
        flex: 1 0 0;
        padding-left: 2em;
        padding-bottom: 5px;
      }
    }
  }
}
