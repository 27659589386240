/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.favoritesList{
  height: 100%;
  width: 100%;
}

.forceOpenSection {
  user-select: none;
  background-color: var(--background1);
  border-left: 2px solid transparent;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  transition: 100ms;
  border-left: 2px solid var(--foreground1);
  background-color: var(--background2);
  overflow: hidden;
  padding-bottom: 12px;
}

.favoritesFolderHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
