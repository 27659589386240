/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";
@import "~@origam/styles/dist/styles/definitions";

.root {
  height: 50px;
  background: var(--background1);
  padding: 0 1em 0 2em;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1em;
  h1 {
    margin: 0;
    margin-right: 0.7em;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    font-size: 1.5em;
    line-height: 1.3em;
    font-weight: 700;
    padding: 0;
  }

  .progressIndicator {
    @include progressIndicator;
  }
}

.workflowActionBtn {
  @include dialogButton();
  background: var(--background3);
  border: 1px solid var(--background5);
  box-sizing: border-box;
  color: var(--background8);
  font-size: $font-size;
  height: 2em;
    margin: 0 0.5em;
  padding: 0 1.5em;
  @include border-radius(3em);
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    background: var(--background5);
      color: var(--background8);
  }
}
