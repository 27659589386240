/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.dialogContent {
  padding: 1.5em 1em;
  white-space: pre-wrap;
  display: flex;
  max-height: 400px;
  max-width: 527px;
}

.errorMessageList {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 350px;
  max-width: 460px;
  flex: 1;
}

.errorMessageListItem {
  flex-shrink: 0;
  min-height: 20px;
  border-top: 1px solid var(--background5);
  &:first-of-type {
    border-top: none;
  }
}

.errorMessageDatetime {
  color: grey;
  margin-right: 15px;
}

.dialogBigIconColumn {
  width: 30px;
  flex: 0 0 auto;
  margin-right: 20px;
  margin-left: 5px;
  svg {
    stroke: transparent;
    fill: var(--error1);
  }
}

.dialogMessageColumn {
  overflow-x: auto;
}
