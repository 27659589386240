/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";
@import "~@origam/styles/dist/styles/definitions";

.Root {
  width: 100%;
  height: 100%;
  overflow: hiddwen;
  display: flex;
  flex-direction: column;
  background-color: var(--background3);
}

.TabHandles {
  height: 20px;
  display: flex;
  flex-direction: row;
  background-color: var(--background7);
  user-select: none;
}

.TabHandle {
  position: relative;
  padding: 2px 20px 2px 10px;
  margin: 0px 1px 1px 0px;
  cursor: pointer;
  background-color: var(--background6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.TabHandleCloseBtn {
  position: absolute;
  top: 0px;
  right: 2px;
  background: transparent;
  padding: 2px;
  border: none;
  cursor: pointer;
}

.TabHandleCloseBtn:hover {
  background-color: var(--foreground6);
  color:var(--background1);
}

.TabHandle:hover {
  background-color: var(--foreground6);
}

.TabHandle.active {
  margin-bottom: 0;
  background-color: var(--background3);
  color:var(--background8);
}

.dialogLoadingContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.actionButtonHeader{
  display: flex;
  flex-direction: row;
}

.workflowActionBtn {
@include dialogButton();
  background: var(--background3);
  border: 1px solid var(--background5);
  box-sizing: border-box;
  color: var(--background8);
  font-size: $font-size;
  height: 2em;
  margin: 0 0.5em;
  padding: 0 1.5em;
@include border-radius(3em);
&:first-of-type {
   margin-left: 0;
 }
&:last-of-type {
   margin-right: 0;
 }
&:hover {
   background: var(--background5);
   color: var(--background8);
 }
}

