/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/definitions";

.root {
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  &:global(.isHoriz) {
    flex-direction: row;
  }
  &:global(.isVert) {
    flex-direction: column;
  }
}

.divider {
  background-color:var(--foreground1);
  flex-shrink: 0;
  :global(.dividerLine) {
    border-color: var(--background1) !important;
		border-style: solid !important;
		@extend %transition;
  }
  &:global(.isHoriz) {
    width: 0.5em;
    cursor: col-resize;
    display: flex;
    flex-direction: column;
    align-items: center;
    :global(.dividerLine) {
      width: 1px;
      height: 100%;
      border-left: 1px dotted var(--background4);
      border-width: 0 1px 0 1px !important;
    }
  }
  &:global(.isVert) {
    height: 0.5em;
    cursor: row-resize;
    display: flex;
    flex-direction: row;
    align-items: center;
    :global(.dividerLine) {
      height: 1px;
      width: 100%;
      border-top: 1px dotted var(--background4);
      border-width: 1px 0 1px 0 !important;
    }
  }
  &:global(.isDragging) {
    position: relative;
    background-color:var(--warning1);
  }
  &:not(:global(.isDragging)):hover {
    background-color:var(--error1);
  }
}

.panel {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: none;
  display: flex;
  flex-direction: column;
  &:global(.isHoriz) {
    height: 100%;
  }
  &:global(.isVert) {
    width: 100%;
  }
}
