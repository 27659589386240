/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/definitions";

.input {
  width: 100%;
  box-sizing: border-box;
  height: 25px;
  padding: 0.25em 0.3em;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  @include border-radius(2px);
  @extend %transition;
  background: var(--background1);
  border: 1px solid var(--background5);
  color: var(--background8);
  margin: 0 0 0.25em 0;
  &:hover, &:focus {
    background: var(--background1);
    border-color: var(--foreground1);
    color: var(--background8);
    font-size: 1em;
  }
  &:last-of-type {
    margin-bottom: 0.5em;
  }
}

.tagEditorDropdown {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  background-color:var(--background1);
  border: 1px solid var(--background4);
}

.tagEditorDropdownItem {
  min-height: 20px;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;
  &:hover {
    cursor: pointer;
    background-color: var(--foreground6);
    color:var(--background1);
  }
}

div.dateTimeInput:read-only {
  background-color:var(--background1);
}

input.dateTimeInput {
  background-color:var(--background1);
  margin: 0 0 0.25em 0;
  height: var(--inputHeight);
}

