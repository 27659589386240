/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.tagInputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  border-radius: 2px;
  background:var(--background2);
}

.tagInputAdd {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
}

.tagInputAdd:hover {
  cursor: pointer;
  font-weight: bold;
  background-color: var(--foreground1);
  color:var(--background2);
}

.tagInputItem {
  background-color: var(--foreground1);
  color:var(--background2);
  margin: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  padding: 3px 5px 3px 3px;
}

.tagInputItemDelete {
  height: 16px;
  width: 16px;
  color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagInputItemDelete:hover {
  cursor: pointer;
  background-color: var(--background6);
}
