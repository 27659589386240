/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";
@import "~@origam/styles/dist/styles/definitions";

.root {
  height: -webkit-fill-available;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 0.3em;
  height: 100%;

  &:hover {
    svg {
      stroke-width: 1.5;
    }
  }

  &:global(.isActive) {
    svg {
      stroke: var(--foreground1) !important;
      stroke-width: 2 !important;
    }
  }

  &:global(.isGreenHover):hover {
    color: var(--ok1);
    svg {
      stroke: var(--ok1) !important;
    }
  }

  &:global(.isRedHover):hover {
    color: var(--error1);
    svg {
      stroke: var(--error1) !important;
    }
  }

  &:global(.isOrangeHover):hover {
    color: var(--warning2);
    svg {
      stroke: var(--warning2) !important;
    }
  }

  svg {
    height: 1.5em;
    width: 1.5em;
    stroke: var(--background6);
    &:hover {
      stroke: var(--background8);
    }
    @include hoverItemAnimation;
  }
}

.isDisabled{
  svg {
    fill: var(--background5) !important;
    stroke: var(--background5) !important;
    stroke-width: 1 !important;
    &:hover {
      stroke: var(--background5);
      fill: var(--background5) !important;
    }
  }
}
