/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";

.resultItem{
  padding: 0 1.6667em;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  color: $textColorNoHover;
  background-color: var(--background2);
  transition: all 300ms linear;
  @include hoverItemAnimation;

  &:not(:global(.isActive)):hover {
    color: $textColorHover;
    svg:global(.icon) {
      stroke: var(--background8);
      stroke-width: 1.5;
    }
  }

  svg:global(.icon) {
    @include hoverItemAnimation;
    height: 20px;
    width: 20px;
  }
  img:global(.icon) {
    @include hoverItemAnimation;
    height: 20px;
    width: 20px;
  }
}

.icon {
  width: 1.6667em;
  height: 1.6667em;
  margin: 0 1em 0 0;
  flex-shrink: 0;
}

.textContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.resultItem:hover {
  color: $textColorHover;
}

.resultItemName{
  font-weight: bold;
}

.resultItemDescription{
  min-height: 18px;
}

.resultGroupRow {
  padding-left: 15px;
  font-weight: bold;
  height: 28px;
  display: flex;
  align-items: center;
  background-color: var(--background2);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.groupName{
  margin-left: 10px;
}


.arrow{
  font-size: 20px;
  color:var(--background8);
}

.dropDownParent > div{
  justify-content: flex-start;
}