/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";
@import "~@origam/styles/dist/styles/EditorsCommon.module";

.blobEditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  :global(.fileName) {
    width: 100%;
    border-radius: 3px;
  }
  :global(.controls) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0;
    @include hoverItemAnimation;
    &:hover {
      opacity: 1;
    }
  }

  :global(.progress) {
    position: absolute;
    top: 0px;
    left: -2px;
    width: 101%;
    height: 101%;
    background-color:var(--background1);
    overflow: hidden;
    border: 1px solid var(--background5);
    border-radius: 3px;
  }

  :global(.progressBar) {
    border-radius: 3px;
    border: none;
    height: 100%;
    background-color: var(--foreground1);
    color:var(--background1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  :global(.btnDownload),
  :global(.btnDelete),
  :global(.customBtnChoose) {
    height: 100%;
    border: 1px solid var(--background5);
    background-color: var(--foreground1);
    color:var(--background1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include hoverItemAnimation;
    &:hover {
      cursor: pointer;
      background-color: var(--foreground4);
      color:var(--background8);
    }
  }
  :global(.btnDownload) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 20px;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    font: inherit;
  }

  :global(.btnDownloadFirst) {
    border-radius: 3px 0px 0px 3px;
    border-right: none;
  }

  :global(.btnDownloadOnly) {
    border-radius: 3px 3px 3px 3px;
  }

  :global(.btnDelete) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 20px;
    cursor: pointer;
    border-radius: 0;
    overflow: hidden;
    border-right: none;
    padding: 0;
    font: inherit;
  }

  :global(.customBtnChoose) {
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 20px;
    border-radius: 0px 3px 3px 0px;
    overflow: hidden;
    position: relative;
    font: inherit;
    input {
      position: absolute;
      left: 100%;
      opacity: 0;
      width: 0.1px;
      height: 0.1px;
    }
  }
}

.imageEditor {
  background-color:var(--background1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  :global(.imageEditorMountpoint) {
    width: 100%;
    height: 100%;
  }
}

.dialogContent {
  padding: 10px;
}

.editorContainer {
  @include editorContainer;
}

.notification {
  @include notification;
}

.standardBorder{
  border: 1px solid var(--background5);
}

.focusedBorder{
  border: 1px solid var(--foreground1);
}
