
.headerIcon{
  font-size: 15px;
  margin-right: 20px;
  color: var(--background4);
}

.headerIconActive{
  color: var(--foreground1);
}

.headerIconHidden{
  display: none;
}