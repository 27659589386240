
:global(.filterRow) {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 25px;
}


.aggregationRow {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  min-height: 25px;
  padding-right: 8px;
}

