/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";
@import "~@origam/styles/dist/styles/definitions";

.droppedPanel {
  @include popoverShadow;
  box-shadow: 0 0.5em 1.5em 0 rgba(var(--background8-rgb), 0.25);
  border: 1px solid var(--background5);
  @include border-radius(4px);
}

.optionGridCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
 white-space: nowrap;
  @include hoverItemAnimation;
  background: var(--background1) !important;
  color: var(--foreground1);
  padding: 0 0.25em;
  &:hover {
    font-weight: 400;
    background: var(--foreground1) !important;
    color: var(--background1);
  }
}

.optionGridCell:global(.a) {
  background-color:var(--background1);
}

.optionGridCell:global(.b) {
  background-color: var(--background2);
}

.noItemsFound {
  font-style: italic;
  background-color:var(--background1);
  color: grey;
  white-space: nowrap;
  padding: 5px;
}

.tagInput{
  height: 23px;
  background-color:var(--background1);
}

:global(.filterRow)  div:global(.tagInput){
  background-color:var(--background1);
}