/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/


.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.expander{
    padding-right: 5px;
}

.treeView{
    background: var(--background1);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1 0 0;
    overflow: scroll;
    padding-left: 24px;
}

.nodeLabel{
    white-space:nowrap;
}

.node{
    display: flex;
    justify-content: space-between;
    height: 20px;
    cursor: pointer;
}

.node:hover{
    background: var(--foreground4);
}

.nodeSelected{
    background: var(--foreground1);
}