/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.mapDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:global(.isHidden) {
    display: none;
  }

  :global(.leaflet-draw-actions) {
    overflow: hidden;
    margin-left: 2px;
    margin-top: -1px;
    border-radius: 5px;
    background-color:var(--background1);
    border: 1px solid var(--background4);
    box-shadow: 0 1px 2px rgba(var(--background8-rgb), 0.65);
    li {
      border-left: 1px solid var(--background4);
    }
    li:first-of-type {
      border-left: none;
    }
    a {
      background: transparent;
      border: none;

      &:hover {
        background-color: var(--background3);
      }
    }
  }

  :global(.markerHighlighted) img { 
    border: 2px solid var(--warning1);
    background-color: rgba(var(--warning3-rgb), 0.274);
  }
}

.mapSearch {
  width: 100%;
  position: relative;
  :global(.input) {
    width: 100%;
    border-radius: 2px;
    transition: all 300ms linear;
    background: var(--background2);
    border: 1px solid var(--background5);
    color: var(--background8);
    padding: 0.25em 0.34em;
  }
  :global(.inputActions) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    button {
      border: none;
      cursor: pointer;
      background: none;
      height: 100%;
      color: var(--background5);
      transition: color 0.3s linear;
      &:hover {
        color:var(--background8);
      }
    }
  }
  :global(.inputClear) {

  }
  :global(.inputCaret) {

  }
}

.mapSearchDropdown {
  position: fixed;
  z-index: 10000;
  background-color:var(--background1);
  border: 1px solid var(--background8);
  border-radius: 3px;
  max-height: 300px;

  :global(.cell) {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    &:hover {
      background-color: var(--background4);
    }
  }
  :global(.c1) {
    background-color: var(--background2);
  }
  :global(.c2) {
    background-color:var(--background1);
  }
}


.mapToolbarButton {
  border: none;
  cursor: pointer;
  background-color:var(--background1);
  color: var(--background5);
  
  transition: color 0.3s linear;
  &:hover {
    color:var(--background8);
  }

  &:global(.isActive) {
    color: var(--foreground1);
  }
}