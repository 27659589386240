/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

div.root{
  height: 100%;
  display: flex;
  flex-direction: column;
}

div.root input:global(.input) {
  max-height: 25px;
  border-right: 1px solid var(--background5);
  border-radius: 2px;

  &:focus {
    margin-right: 0;
  }
}

div.root div:global(.cell) {
  &:global(.selected){
    &:global(.ord1){
      background: var(--foreground1);
      color: var(--background1);
    }
    &:global(.ord2){
      background: var(--foreground1);
      color: var(--background1);
    }
  }
}
.root :global(.cell) {
  &:global(.withCursor){
    &:global(.ord1){
      background: var(--background2);
      color: var(--background8);
    }
    &:global(.ord2){
      background: var(--background1);
      color: var(--background8);
    }
  }
}

.root :global(.isHoveredRow) div:global(.cell) {
  &:global(.ord1){
    background: var(--background2);
    color: var(--background8);
  }
  &:global(.ord2){
    background: var(--background1);
    color: var(--background8);
  }
}


