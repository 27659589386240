.resultIemRow {
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 28px;
}

.resultIemRowHovered {
  cursor: pointer;
  background-color: var(--foreground4);
  color:var(--background8) !important;
}

.resultIemRowSelected{
  background-color: var(--foreground1);
  color:var(--background1) !important;
}
.resultIemRowSelected div svg{
  stroke:var(--background1);
  stroke-width: 1.5;
}

.itemIcon{
  min-width: 20px;
  max-width: 20px;
  height: 20px;
}

.itemContents{
  white-space: nowrap;
  display: flex;
  align-items: center;
  user-select: none;
  margin-right: 10px;
}

.itemTitle {
  font-weight: bold;
  margin-left: 10px;
}

.itemTextSeparator{
  white-space: pre-wrap;
}
