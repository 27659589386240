/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

:root {
  --inputHeight: 25px;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: var(--background1);
  color: var(--background8);
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
}

textarea {
  resize: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

#root {
  overflow: none;
  display: flex;
  flex-direction: column;
  /*padding: 40px;*/
  height: 100%;
}

.toplevelContainer {
  margin: 0;
  padding: 0;
  overflow: none;
  height: 100%;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

:root {
  --font-size: 20px;
}

#dropdown-portal {
  position: absolute;
  top: 0px;
  left: 0px;
}

.tippy-tooltip {
  max-height: 400px;
}

.tippy-popper {
  transition: none;
}

.tippy-popper [x-circle] {
  display: none !important;
}

.tippy-tooltip.tippy-tooltip--regular, .tippy-popper.tippy-tooltip.light-theme[data-animatefill] {
  background-color: var(--background1) !important;
}

.leaflet-control-container,
.leaflet-draw-actions a {
  color: var(--background8) !important;
}

@media print {
  .noPrint {
    display: none;
  }

  h1.printOnly {
    display: block;
  }
  html > body {
    overflow: visible;

    #root div {
      overflow: visible;
    }
  }
}

.printOnly {
  display: none;
}
