/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/definitions";

.container {
  width: 100%;
  position: relative;
}

.trigger {
  align-items: center;
  position: relative;
  box-sizing: border-box;
  height: 25px;
  padding: 0.25em 0.3em;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
 white-space: nowrap;
  word-wrap: normal;
  @include border-radius(2px);
  @extend %transition;
  background: var(--foreground1);
  border: 1px solid var(--foreground1);
  color: var(--background1);
  cursor: pointer;
  margin: 0 0 0.5em 0;
  &:hover {
    background: var(--background1);
    color: var(--foreground1);
  }
}

.dropdownSymbol {
  position: absolute;
  right: 0px;
  padding-right: 5px;
}

.dropdown {
  position: absolute;
  left: 0px;
  top: 21px;
  z-index: 1000;
  background-color:var(--background1);
  color:var(--background8);
  min-width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5em 1.5em 0 rgba(var(--background8-rgb), 0.25);
  border: 1px solid var(--background5);
  box-sizing: border-box;
  @include border-radius(4px);
}

.dropdownItem {
  min-height: 20px;
  display: flex;
  align-items: center;
  color: var(--foreground1);
  cursor: pointer;
  font-weight: 400;
  padding: 0 0.5em;
  &:hover {
    background: var(--foreground1);
    color: var(--background1);
  }
}


