/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.editor {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 12px;
  border: 1px solid var(--background5);
  background-color: var(--background2);
  padding-left: 10px;
}

.editor:hover:not(:read-only) {
  border: 1px solid var(--foreground6);
  outline: var(--foreground6) solid 1px;
  position: relative;
  z-index: 100;
}

.editor:read-only {
  background-color: var(--background3);
}

.editor[type="checkbox"] {
  width: initial;
  height: initial;
  background: initial;
}

.editorContainer {
  position: relative;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: center;
}

.editorContainer > div[data-tooltipped] {
  width: 100%;
  height: 100%;
  display: flex;
}


.notification {
  height: 100%;
  position: absolute;
  right: -15px;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 100;
}

.notification :global(.red) {
  color: var(--error1);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}