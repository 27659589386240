/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.navigationBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: var(--viewSideMargin);
  margin-right: var(--viewSideMargin);
  margin-bottom: 5px;
  a {
    height: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  :last-child{
    font-size: 15px;
  }
}

.breadcrumb{
  text-decoration: underline;
  color: var(--foreground1);
}

.pathSeparator{
  margin-left: 3px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  font-size: 15px;
}