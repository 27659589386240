/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/definitions";
@import "~@origam/styles/dist/styles/commonStyles";

.avatarSection {
  display: flex;
  flex-direction: row;
  padding: 1em;
}

.avatarContainer {
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--background3);
  height: 3em;
  width: 3em;
  overflow: hidden;
}

.avatar {
  width: 3em;
}

.clickableAvatar{
  cursor: pointer;
}

.pictureSection {
  flex-shrink: 0;
  width: 3em;
}

.infoSection {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  font-size: $h2-fontsize;
  line-height: $h2-lineheight;
  font-weight: 700;
  margin: 0 0 0 1em;
}

.userNameLabel {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  color: var(--background6);
  cursor: pointer;
  margin: 0 0 0 1em;
  @include hoverItemAnimation;
}

.userNameLabel:hover  {
  color: var(--background8);
}

.root {
  min-width: 250px;
}
