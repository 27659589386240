/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "../../../../../../node_modules/@origam/styles/dist/styles/EditorsCommon.module";
@import "../../../../../../node_modules/@origam/styles/dist/styles/commonStyles";
@import "../../../../../../node_modules/@origam/styles/dist/styles/definitions";

.container {

}

.dayTable {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  border: none;
  height: 30px;
}

:global(.calendarWidgetCell) {
  width: 14.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: auto;
  border: none;
  background: var(--background1);
  color: var(--background8);
}

.cell:hover {
  background-color: var(--background4);
}

.selectedDay {
  background: var(--foreground1);
  color: var(--background1);
  font-weight: 700;
  @include border-radius(5em);
}

.dayHeaderCell {
  width: 14.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  border: none;
  background: var(--background1);
  color: var(--background8);
  font-size: 0.9em;
  font-weight: 700;
}

.neighbourMonthCell {
  color: var(--background5);
}

.header {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  background: var(--background3);
  border-bottom: 1px solid var(--background5);
}

.controlBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  box-sizing: content-box;
  color: var(--foreground1);
  height: 1em;
  line-height: 0.8em;
  margin: 0;
  padding: 0;
  width: 14.3%;
  @extend %transition;

  &:hover {
    background: var(--foreground1);
    color: var(--background1);
    @include border-radius(5em);
  }
  i {
    font-size: 1.25em;
    line-height: 0.8em;
  }
}

.title {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1em;
  font-weight: 700;
}