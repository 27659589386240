/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.dataView {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  overflow: hidden;
}

.dataViewOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataViewLoadingLabel {
  padding: 20px;
  background-color: var(--foreground6);
  color:var(--background1);
  text-transform: uppercase;
}

.overlayContainer {
  position: relative;
}

.dataViewContentContainer {
  position: relative;
  flex-grow: 1;
}
