/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/EditorsCommon.module";

.root {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.columnConfigs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.columnConfigHeader{
  background: var(--background2);
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--viewSideMargin);
  padding-right: var(--viewSideMargin);
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: var(--viewSideMargin);
  height: 40px;
}

.lockedColumnsRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--viewSideMargin);
  padding-right: var(--viewSideMargin);
  margin-top: 10px;
}

.buttonRow{
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0 10px 0;
  align-items: center;
   > button {
     margin: 0;
   }
  > div {
     flex-grow: 0;
     align-items: stretch;
  }
}

.arrow {
  font-size: 20px;
  width: 15px
}

.input {
  border: 1px solid var(--background5);
  background-color: var(--background1);
  border-radius: 2px;
  width: 95px;
  height: 23px;
  padding: 0.25em 0.34em;
}

.dropdown {
  width: 95px;
}