/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";

.disabled {
  color: var(--background5);
}
.enabled {
  color: var(--background7);

  &:hover {
    background: var(--background5);
    color: var(--background8);
  }
}

.root {
  height: 22px;
  padding: 0 0.75em;
  background: var(--background3);
  border-color: var(--background5);
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: -webkit-min-content;
  //width: -moz-min-content;
  //width: min-content;
  cursor: pointer;
  font-size: 0.9em;
  @include hoverItemAnimation;

  &:global(.hidden) {
    //display: none;
    position: absolute;
    top: 110%;;
    left: 0;
  }

  border-radius: 3em;
  margin-left: 0.2em;

  &:not(:global(.hidden)):first-of-type {
    margin-left: 0;
  }
/*
  &:not(:global(.hidden)):first-of-type {
    border-radius: 3em 0em 0em 3em;
    border-right: none;
    margin-right: 0;
  }

  &:not(:global(.hidden)):last-of-type {
    border-radius: 0em 3em 3em 0em;
    border-left: none;
    margin-left: 0;
  }

  &:first-of-type:last-of-type {
    border-radius: 3em;
    border-color: var(--background5);
    border-style: solid;
    border-width: 1px;
  }*/
}
