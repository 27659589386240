/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "../../../../../../node_modules/@origam/styles/dist/styles/EditorsCommon.module";
@import "../../../../../../node_modules/@origam/styles/dist/styles/commonStyles";
@import "../../../../../../node_modules/@origam/styles/dist/styles/definitions";

.droppedPanelContainer {
  z-index: 1000;
  background-color:var(--background1);
  overflow: hidden;
  @include popoverShadow;
  width: 224px;

  box-shadow: 0 0.5em 1.5em 0 rgba(var(--background8-rgb), 0.25);
  border: 1px solid var(--background5);
  @include border-radius(4px);
}

.dropdownSymbol {
  @include dropdownSymbol;
}

.input {
  @include input;
  padding-right: 18px;
}

.notification {
  @include notification;
}

.editorContainer {
  @include editorContainer;
}


.formatHintTooltip {
  padding: 5px;
  position: fixed;
  border: 1px solid var(--background8);
  background-color:var(--background1);
  z-index: 5000;
}