/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

.root {
  position: absolute;
  top: 8px;
  left: calc(12px + 1.6667em);

  background: var(--error1);
  border: 1px solid var(--background1);
  color: var(--background1);
  font-size: 0.9em;
  font-weight: 700;
  height: 1.6em;
  line-height: 1.5em;
  padding: 0 0.25em;
  text-align: center;
  min-width: 1.6em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -ms-border-radius: 2em;
  -o-border-radius: 2em;
  border-radius: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}