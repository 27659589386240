/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

@import "~@origam/styles/dist/styles/commonStyles";
@import "~@origam/styles/dist/styles/definitions";

.root {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  @include hoverItemAnimation;
  color: var(--foreground1);
  height: 30px;
  padding: 0 1em;

  &:not(:global(.isDisabled)):hover {
    background-color: var(--foreground1);
    //font-weight: 400;
  }

  &:global(.isDisabled) {
    color: grey;
    cursor: not-allowed;
  }

  &:global(.redItem) {
    color: var(--error1);
    background-color:var(--background1);
    &:hover {
      color:var(--background1);
      background-color: var(--error1);
    }
  }
  &:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:hover {
    background: var(--foreground1);
    color: var(--background1);
  }
  &:global(.isDisabled) {
    color: var(--background5);
    &:hover {
      background: var(--background2);
    }
  }
}

.isSelected{
  font-weight: bold;
}